import { h } from 'preact';
import classnames from 'classnames';
import faArrowRight from '@fortawesome/fontawesome-pro/svgs/regular/arrow-right.svg';
import faPen from '@fortawesome/fontawesome-pro/svgs/regular/pencil.svg';
import faCheck from '@fortawesome/fontawesome-pro/svgs/regular/check.svg';

import FaIcon from '../faIcon';
import RadioIcon from './radioIcon';
import Loader from '../loader';
import SVG from '../SVG';

import style from './style.scss';

const RightIcon = (props) => {
  const {
    next,
    loading,
    radio,
    active,
    radioActive,
    edit,
    success,
  } = props;

  if (loading) {
    return (
      <span className={style.loadingIndicator}>
        <Loader secondary disabled />
      </span>
    );
  }

  if (next) {
    return (
      <span className={style.icon}>
        <FaIcon icon={faArrowRight} />
      </span>
    );
  }

  if (radio) {
    return (
      <span className={style.icon}>
        <RadioIcon active={active || radioActive} />
      </span>
    );
  }

  if (edit) {
    return (
      <span className={style.primaryColoredIcon}>
        <FaIcon icon={faPen} />
      </span>
    );
  }

  if (success) {
    return (
      <span className={style.icon}>
        <FaIcon icon={faCheck} />
      </span>
    );
  }

  return null;
};

const Button = (props) => {
  const {
    children,
    onClick,
    disabled,
    ariaLabel = 'button',
    type = 'button',
    leftIcon,
    className,
    primary,
    secondary,
    edit,
    loading,
    ellipsis,
    active,
    square,
    transparent,
    radio,
    next,
    bold,
    success,
    danger,
  } = props;

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classnames(style.default, className, {
        [style.primary]: primary,
        [style.secondary]: secondary || edit,
        [style.loading]: loading,
        [style.ellipsis]: ellipsis,
        [style.active]: active,
        [style.square]: square,
        [style.transparent]: transparent,
        [style.increasePadding]: radio || next,
        [style.bold]: bold,
        [style.success]: success,
        [style.danger]: danger,
        [style.disabled]: disabled,
      })}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {leftIcon && <SVG src={leftIcon} className={style.leftImage} />}
      <span className="btn-text">{children}</span>
      <RightIcon {...props} />
      {!disabled && <span className={style.shade} />}
    </button>
  );
};

export default Button;
